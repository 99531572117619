import { useState, useEffect } from "react";
import Header from './components/Header'
import Footer from './components/Footer'
import Reward from './pages/Reward'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
function App() {
  const {
    account
  } = useWeb3React();
  const [currentAccount, setCurrentAccount] = useState('');
  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
  };

  useEffect(() => {
    if (account) {
      setCurrentAccount(account);
    }
  })
  return (
    <div className="text-4xl font-bold bg-main">
      <BrowserRouter>
        <Header currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} />
        <Routes>
          <Route path="/" element={<Reward currentAccount={currentAccount} />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
