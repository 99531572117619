import React from 'react'
import LogoText from '../assets/icons/Logo.svg'
import Logo from '../assets/icons/Logo White.svg'
import Twitter from '../assets/icons/Twitter.svg'
import Discord from '../assets/icons/Discord.svg'
import Telegram from '../assets/icons/Telegram.svg'
import Socials from './socials'
export default function Footer() {

    const style = {
        wrapper: {
            padding: '20px 0'
        }
    }

    return (
        <div className='container-div text-white bg-header'>
            <div style={style.wrapper} className='flex justify-between footer-desktop'>
                <div className='flex items-center gap-x-4'>
                    <img src={Logo} alt="" />
                    <img src={LogoText} alt="" />
                    <div>contact us</div>
                </div>
                <div className='flex items-center gap-x-4'>
                    <Socials />
                </div>
            </div>
            <div style={style.wrapper} className='flex flex-col justify-center footer-mobile'>
                <div className='flex justify-center items-center gap-x-4'>
                    <Socials />
                </div>
                <div className='flex justify-center items-center gap-x-4'>
                    <img src={Logo} alt="" />
                    <img src={LogoText} alt="" />
                </div>
            </div>
        </div>
    )
}
