import React, { useEffect, useState } from 'react'
import { getTokenDetails } from '../../utils/api'
import Contract from '../../web3';
export default function SectionTwo() {
    const style = {
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            rowGap: '8px',
            flexDirection: 'column',
            alignItems: 'center',
            // width: '50%'
        },
    }
    const [tokenPrice, setTokenPrice] = useState(0);
    const [supply, setSupply] = useState(0);
    const [marketcap, setMarketcap] = useState(0);
    useEffect(async() => {
        
        var res = await getTokenDetails();
        var price = res.data.data.price;
        var filterPrice = res.data.data.price.toString().slice(0, 10);
        
        setTokenPrice(filterPrice);
        const totalSupply = 10000000000;
        
        const marketCapValue = (price * totalSupply).toString().slice(0, 10);
        // alert(marketCapValue)
        setMarketcap(marketCapValue);
    }, [])
    return (
        <div style={{
            marginTop: '20px',
            color: '#fff'
        }} className='container flex section-tow-container'>
            <div className='section-two-wrapper' style={ style.wrapper }>
                <div style={{
                    fontSize: '20px'
                }} className='font-montserrat'>CURRENT PRICE (USD)</div>
                <div className='word-wrap token-price'>${tokenPrice}</div>
            </div>
            <div className='section-two-seprator'></div>
            <div className='section-two-wrapper' style={ style.wrapper }>
                <div style={{
                    fontSize: '20px'
                }} className='font-montserrat'>MARKET CAP</div>
                <div className='word-wrap token-price'>${marketcap}</div>
            </div>
        </div>
    )
}