import React, { useEffect, useState } from "react";
import Button from "../../components/Button";

export default function Calculator() {
  const [selected, setSelected] = useState(0);
  const [totalBUSD, setTotalBUSD] = useState(0);
  const [tokens, setTokens] = useState("");
  const style = {
    container: {
      overflow: "hidden",
    },
    label: {
      fontSize: "20px",
      fontWeight: "bolder",
    },
    inputWrapper: {
      width: "100%",
    },
    input: {
      background: "#5000EA",
      border: "none",
      borderRadius: "5px",
      padding: "15px 15px",
      color: "#fff",
    },
  };
  useEffect(() => {
    var res = (tokens / 70000000000) * (selected === 0 ? 1000000 : selected);
    setTotalBUSD(res);
  }, [selected, tokens]);
  return (
    <div
      style={style.container}
      className="sectionMargin flex flex-col gap-y-4 text-white"
    >
      <div className="anation-amount-label" style={style.label}>
        ANATION AMOUNT
      </div>
      <div style={style.inputWrapper} className="flex gap-x-4 input-wrapper">
        <input
          className="holder-input"
          value={tokens}
          onChange={(e) => setTokens(e.target.value)}
          style={style.input}
          type="number"
          placeholder="some"
        />
        <Button className="calculate-btn" text="CALCULATE"></Button>
      </div>
      <div className="volum-label" style={style.label}>
        24H VOLUME
      </div>
      <div className="flex gap-x-4 volume-filter-wrapper-desktop">
        <div className="volume-filter">
          <Button
            onClick={() => setSelected(100000)}
            className={`${selected === 100000 ? "activeBtn" : ""} filterBtn`}
            text="$100K"
          ></Button>
        </div>
        <div className="volume-filter">
          <Button
            onClick={() => setSelected(500000)}
            className={`${selected === 500000 ? "activeBtn" : ""} filterBtn`}
            text="$500K"
          ></Button>
        </div>
        <div className="volume-filter">
          <Button
            onClick={() => setSelected(1000000)}
            className={`${selected === 1000000 ? "activeBtn" : ""} filterBtn`}
            text="$1M"
          ></Button>
        </div>
        <div className="volume-filter">
          <Button
            onClick={() => setSelected(5000000)}
            className={`${selected === 5000000 ? "activeBtn" : ""} filterBtn`}
            text="$5M"
          ></Button>
        </div>
        <div className="volume-filter">
          <Button
            onClick={() => setSelected(10000000)}
            className={`${selected === 10000000 ? "activeBtn" : ""} filterBtn`}
            text="$10M"
          ></Button>
        </div>
        <div className="volume-filter">
          <Button
            onClick={() => setSelected(20000000)}
            className={`${selected === 20000000 ? "activeBtn" : ""} filterBtn`}
            text="$20M"
          ></Button>
        </div>
      </div>
      <div className="flex gap-x-4 volume-filter-wrapper-mobile">
        <div className="flex flex-col gap-y-4">
          <div className="volume-filter">
            <Button
              onClick={() => setSelected(100000)}
              className={`${selected === 100000 ? "activeBtn" : ""} filterBtn`}
              text="$100K"
            ></Button>
          </div>
          <div className="volume-filter">
            <Button
              onClick={() => setSelected(500000)}
              className={`${selected === 500000 ? "activeBtn" : ""} filterBtn`}
              text="$500K"
            ></Button>
          </div>
          <div className="volume-filter">
            <Button
              onClick={() => setSelected(1000000)}
              className={`${selected === 1000000 ? "activeBtn" : ""} filterBtn`}
              text="$1M"
            ></Button>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="volume-filter">
            <Button
              onClick={() => setSelected(5000000)}
              className={`${selected === 5000000 ? "activeBtn" : ""} filterBtn`}
              text="$5M"
            ></Button>
          </div>
          <div className="volume-filter">
            <Button
              onClick={() => setSelected(10000000)}
              className={`${  
                selected === 10000000 ? "activeBtn" : ""
              } filterBtn`}
              text="$10M"
            ></Button>
          </div>
          <div className="volume-filter">
            <Button
              onClick={() => setSelected(20000000)}
              className={`${
                selected === 20000000 ? "activeBtn" : ""
              } filterBtn`}
              text="$20M"
            ></Button>
          </div>
        </div>
      </div>
      <div style={style.label} className="res-reward-label">
        TOTAL BUSD REWARDS (ESTIMATE)
      </div>
      <div style={style.res} className="res-reward-label token-price">
        {totalBUSD} BUSD
      </div>
    </div>
  );
}
