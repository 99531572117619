import React, { useState } from "react";
import Button from "../../components/Button";
import Fees from "./Fees";
import Calculator from "./Calculator";
import Graph from "../../components/Graph";
export default function SectionThree() {
  const [active, setActive] = useState("calculator");
  return (
    <div className="container sectionMargin">
      <div className="flex gap-x-4 button-group-desktop">
        <Button
          onClick={() => setActive("calculator")}
          className={`${active === "calculator" ? "activeBtn" : ""}`}
          text="REWARDS CALCULATOR"
        ></Button>
        <Button
          onClick={() => setActive("fees")}
          className={`${active === "fees" ? "activeBtn" : ""}`}
          text="FEES"
        ></Button>
        <Button
          onClick={() => setActive("graph")}
          className={`${active === "graph" ? "activeBtn" : ""} graphBtn`}
          text="CHART"
        ></Button>
        <Button
          onClick={() => setActive("swap")}
          className={`${active === "swap" ? "activeBtn" : ""}`}
          text="SWAP"
        ></Button>
      </div>
      <div className="flex flex-col gap-x-4 gap-y-4 button-group-mobile">
        <Button
          onClick={() => setActive("calculator")}
          className={`${active === "calculator" ? "activeBtn" : ""}`}
          text="REWARDS CALCULATOR"
        ></Button>
        <div className="flex gap-x-4 justify-center">
          <Button
            onClick={() => setActive("fees")}
            className={`${active === "fees" ? "activeBtn" : ""}   feeBtnMobile`}
            text="FEES"
          ></Button>
          <a
            style={{ textAlign: "center", textDecoration: "none" }}
            target="_blank"
            href="https://dexscreener.com/bsc/0x1F5Cb99149cD545e62a764faca59F9A4E13ffdb3?embed=1&theme=dark&trades=0"
            className={`${
              active === "swap" ? "activeBtn" : ""
            } font-orbitation btn`}
          >
            CHART
          </a>
        </div>
        <a
          style={{ textAlign: "center", textDecoration: "none" }}
          target="_blank"
          href="https://app.bogged.finance/bsc/swap?&embed=1&tokenIn=BNB&tokenOut=0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95&theme=dark"
          className={`${
            active === "swap" ? "activeBtn" : ""
          } font-orbitation btn`}
        >
          SWAP
        </a>
      </div>
      <div>
        <div
          style={{
            display: `${active === "calculator" ? "block" : "none"}`,
          }}
        >
          <Calculator />
        </div>
        <div
          style={{
            display: `${active === "fees" ? "block" : "none"}`,
          }}
        >
          <Fees />
        </div>
        <div
          style={{
            display: `${active === "graph" ? "block" : "none"}`,
          }}
        >
          <Graph />
        </div>
        <div
          style={{
            display: `${active === "swap" ? "block" : "none"}`,
            marginTop: "20px",
          }}
        >
          <iframe
            src="https://app.bogged.finance/bsc/swap?&embed=1&tokenIn=BNB&tokenOut=0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95&theme=dark"
            frameborder="0"
            height="780px"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
