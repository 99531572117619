import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/Logo.png'
import yellow from '../../assets/icons/yellow.svg'
import Contract, { Contract_Proxy } from '../../web3'
import { dividendDecimal } from '../../utils/ContractDetails'
import Copy from '../../assets/icons/copy-icon.svg'
import './style.css'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
export default function SectionOne({ currentAccount }) {
    const style = {
        detailDiv: {
            // fontSize: '16px',
            // columnGap: '20px',
            // marginTop: '7px'
        },
        bolderDiv: {
            fontWeight: 'bolder'
        },
        normalDiv: {
            fontWeight: 'normal',
            color: '#fff',
            textDecoration: 'none',
            textAlign: 'center'
        },
        copyIcon: {
            cursor: 'pointer',
            color: '#fff',
            width: '12px',
            height: '12px',
            marginLeft: '10px'
        }
    }
    const [balance, setBalance] = useState(0);
    const [claimableRewards, setClaimableReward] = useState(0);
    const [pendingRewards, setPendingReward] = useState(0);
    const [totalRewards, setTotalReward] = useState(0);
    const addressRef = useRef(null);
    useEffect(async () => {
        // var initialSupply = await Contract.methods.totalSupply().call();
        // setSupply(initialSupply);
        // 0x89BA14FB92Df9313E83e1e84F18A162A757Ca8E1
        // currentAccount = '0xfc7CF7987535eF230aECC065263035050618278C'
        // if (!window.ethereum) {
        //     alert('Metamast Not installed')
        //     return;
        // }
        var rewardDetails = await Contract.methods.getAccount(currentAccount).call();
        var pending = await Contract.methods.withdrawableDividendOf(currentAccount).call();
        var claimable = await Contract.methods.dividendOf(currentAccount).call();
        var total = await Contract.methods.withdrawnDividendOf(currentAccount).call();
        setClaimableReward(claimable)
        setTotalReward(total);
        setPendingReward(pending)
        if (currentAccount === undefined) {
            setBalance(0)
        } else {
            var userBalance = await Contract.methods.balanceOf(currentAccount).call();
            setBalance(userBalance);
        }
    }, [currentAccount])

    const handleClaimClick = async () => {

        // console.log(Contract_Proxy.methods)
        const res = await Contract_Proxy.methods.claim().send({
            from: currentAccount
        });
    }
    const copyAdderss = (e) => {
        navigator.clipboard.writeText('0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95')
        alert('Contract address copied clipboard')
    }
    const copyAdderss2 = (e) => {
        navigator.clipboard.writeText('0xe9e7cea3dedca5984780bafc599bd69add087d56')
        alert('Contract address copied clipboard')
    }
    return (
        <div className='section-one'>
            <div className='flex gap-x-4 section-one-container'>
                <div className='container section-one-left-div' style={{
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                    paddingTop: '150px'
                }}>
                    <img className='coinLogo' src={Logo} alt="" />
                    <div className='claimable'>CLAIMABLE REWARDS</div>
                    <div className='flex items-center gap-x-4 justify-center'>
                        <img src={yellow} alt="" />
                        <p style={{
                            fontSize: '25px',
                            fontWeight: '700'
                        }}>{(claimableRewards / dividendDecimal).toFixed(2)} BUSD</p>
                    </div>
                    <Button onClick={handleClaimClick} text='CLAIM'></Button>
                    <div className='font-montserrat rewards flex items-center justify-between reward-wrapper'>
                        <div className='p'>Pending Rewards: </div>
                        <span className='span'>{(pendingRewards / dividendDecimal).toFixed(2)} BUSD</span>
                    </div>
                    <div className='font-montserrat rewards flex items-center justify-between reward-wrapper'>
                        <div className='p'>Total Rewarded:</div>
                        <div className='span'>{(totalRewards / dividendDecimal).toFixed(2)} BUSD</div>
                    </div>
                </div>
                <div className='flex flex-col gap-y-4 section-one-right-div'>
                    <div className='container flex items-center justify-between balance-wrapper'>
                        <div className='balance'>BALANCE:</div>
                        <div className='balance-amount'>{(balance / 1000000000).toFixed(2)} ANATION</div>
                    </div>
                    <div style={{ height: '100%' }} className='container'>
                        <div className='token-details-label'>TOKEN DETAILS</div>
                        <div style={{
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            rowGap: '20px'
                        }}>
                            <div style={style.detailDiv} className='flex font-montserrat token-name-wrapper info-wrapper'>
                                <div style={style.bolderDiv}>Token Name:</div>
                                <div style={style.normalDiv}>A-NATION</div>
                            </div>
                            <div style={style.detailDiv} className='flex symbol-wrapper font-montserrat info-wrapper'>
                                <div style={style.bolderDiv}>Symbol:</div>
                                <div style={style.normalDiv}>ANATION</div>
                            </div>
                            <div style={style.detailDiv} className='flex font-montserrat info-wrapper'>
                                <div style={style.bolderDiv}>Total Supply:</div>
                                <div style={style.normalDiv}>10,000,000,000</div>
                            </div>
                            <div style={style.detailDiv} className='flex font-montserrat info-wrapper'>
                                <div style={style.bolderDiv}>Blockchain:</div>
                                <div style={style.normalDiv}>BSC - BINANCE SMART CHAIN (BEP20)</div>
                            </div>
                            <div style={style.detailDiv} className='flex font-montserrat info-wrapper contract-address'>
                                <div style={style.bolderDiv}>ANATION Contract:</div>
                                <div>
                                    <a className='word-wrap' target='_blank' href='https://bscscan.com/token/0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95' style={style.normalDiv}>0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95</a>
                                    <img onClick={copyAdderss} style={style.copyIcon} src={Copy} alt="" />
                                </div>
                            </div>
                            <div style={style.detailDiv} className='flex font-montserrat info-wrapper contract-address'>
                                <div style={style.bolderDiv}>BUSD Contract:</div>
                                <div>
                                    <a className='word-wrap' target='_blank' href='https://bscscan.com/token/0xe9e7cea3dedca5984780bafc599bd69add087d56' style={style.normalDiv}>0xe9e7cea3dedca5984780bafc599bd69add087d56</a>
                                    <img onClick={copyAdderss2} style={style.copyIcon} src={Copy} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}