import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
});

const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/338e667caa404b4bbf58074e2cd4777b`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});
walletconnect.on("disconnect", (code, reason) => {
  console.log(code, reason);
});
const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/338e667caa404b4bbf58074e2cd4777b`,
  appName: "anation"
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink
};
