import React from 'react'
import Twitter from '../assets/icons/Twitter.svg'
import Vimeo from '../assets/icons/vimeo.png'
import Facebook from '../assets/icons/facebook.png'
import Instagram from '../assets/icons/Instagram.png'
import Telegram from '../assets/icons/Telegram.svg'
export default function Socials() {
    return (
        <>
            <a target='_blank' href="https://twitter.com/anationtoken">
                <img src={Twitter} alt="" />
            </a>
            <a target='_blank' href="https://t.me/anationToken">
                <img src={Telegram} alt="" />
                {/* <img src={Discord} alt="" /> */}
            </a>
            <a target='_blank' href="https://facebook.com/anationToken">
                <img src={Facebook} alt="" />
            </a>
            <a target='_blank' href="https://vimeo.com/channels/anationhowto">
                <img  src={Vimeo} alt="" />
            </a>
            <a target='_blank' href="https://www.instagram.com/anationToken/">
                <img  src={Instagram } alt="" />
            </a>
        </>
    )
}
