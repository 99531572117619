import React, { useState } from "react";
import Logo from "../assets/icons/Logo.svg";

import Button from "./Button";
import { connect } from "../utils/Metamask";
import Socials from "./socials";
import ArrowDown from "../assets/icons/arrow down.svg";
import Hamburger from "../assets/icons/hamburger.png";
import Model from "./Model";
import { useWeb3React } from "@web3-react/core";
export default function Header({ currentAccount, setCurrentAccount }) {
  const [showInput, setShowInput] = useState(false);
  const [showSidenav, setShowSideNav] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const style = {
    container: {
      padding: "20px 5%",
      position: "fixed",
      width: "90vw",
      left: "0",
      top: "0",
      zIndex: "1000",
      paddingTop: "50px",
    },
    connectBtn: {
      color: "#fff",
      background: "none",
      padding: "12px 20px",
      border: "3px solid #fff",
      borderRadius: "50px",
    },
    manual: {
      position: "fixed",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "60%",
    },
    input: {
      background: "none",
      border: "none",
      width: "100%",
      padding: "5px",
      textAlign: "center",
      color: "#909090",
      borderBottom: "1px solid #707070",
    },
    manualButton: {
      textAlign: "right",
      width: "100%",
      cursor: "pointer",
    },
    span: {
      transform: "rotate(180deg)",
    },
    arrow: {
      width: "15px",
      // height: '15px'
    },
  };
  const handleClick = async () => {
    var account = await connect();
    if (!account) {
      alert("No account connected");
      return;
    }
    setCurrentAccount(account);
    setShowModel(false);
  };
  const handleHamburgerClick = () => {
    setShowSideNav(!showSidenav);
  };
  const { deactivate } = useWeb3React();
  return (
    <>
      <Model
        setShowModel={setShowModel}
        showModel={showModel}
        handleClick={handleClick}
      />
      <div
        style={style.container}
        className="header-big flex text-white justify-between items-center bg-header "
      >
        <div style={style.manual}>
          <input
            onChange={(e) => setCurrentAccount(e.target.value)}
            style={style.input}
            className={`${showInput ? "show" : "hide"}`}
            type="text"
            placeholder="Enter you wallet address"
            name=""
            id=""
          />
          <div
            style={{
              columnGap: "10px",
              cursor: "pointer",
            }}
            className="flex"
            onClick={() => setShowInput(!showInput)}
          >
            <div style={style.manualButton}>Manual Check</div>
            <img style={style.arrow} src={ArrowDown} alt="" />
          </div>
        </div>
        <div style={style.navItems} className="flex gap-x-4">
          <img src={Logo} alt="" />
          <div className="navItem">REWARDS DAPP</div>
          <a
            target="_blank"
            href="https://anation.media"
            className="navItem link"
          >
            Website
          </a>
        </div>
        <div className="flex items-center gap-x-4">
          <Socials />
          <Button
            onClick={() => {
              if (currentAccount) {
                deactivate();
                setCurrentAccount("");
              } else {
                setShowModel(true);
              }
            }}
            text={`${currentAccount === "" ? "CONNECT" : "CONNECTED"}`}
          ></Button>
        </div>
      </div>
      <div
        style={style.container}
        className="header-mobile flex text-white justify-between items-center bg-header "
      >
        <div style={style.manual}>
          <input
            onChange={(e) => setCurrentAccount(e.target.value)}
            style={style.input}
            className={`${showInput ? "show" : "hide"}`}
            type="text"
            placeholder="Enter you wallet address"
            name=""
            id=""
          />
          <div
            style={{
              columnGap: "10px",
              cursor: "pointer",
            }}
            className="flex"
            onClick={() => setShowInput(!showInput)}
          >
            <div style={style.manualButton}>Manual Check</div>
            <img style={style.arrow} src={ArrowDown} alt="" />
          </div>
        </div>
        <img
          onClick={handleHamburgerClick}
          className="hamburger"
          src={Hamburger}
          alt=""
        />
        <div style={style.navItems} className="flex gap-x-4">
          <img
            style={{
              width: "140px",
            }}
            src={Logo}
            alt=""
          />
        </div>
        <div className="flex items-center gap-x-4">
          <Button
            className="text-12"
            onClick={() => {
              if (currentAccount) {
                console.log('Disconnect')
                deactivate();
                setCurrentAccount("");
              } else {
                setShowModel(true);
              }
            }}
            text={`${currentAccount === "" ? "CONNECT" : "CONNECTED"}`}
          ></Button>
        </div>
        <div className={`side-nav ${showSidenav ? "show" : "hide"}`}>
          {/* <img src={Hamburger} alt="" /> */}
          <div className="side-nav-items-wrapper">
            <div className="navItem">REWARD DAPP</div>
            <a
              target="_blank"
              href="https://anation.media"
              className="navItem link"
            >
              Website
            </a>
            <a
              target="_blank"
              href="https://dexscreener.com/bsc/0x1F5Cb99149cD545e62a764faca59F9A4E13ffdb3?embed=1&theme=dark&trades=0"
              className="navItem link"
            >
              CHART
            </a>
            <a
              target="_blank"
              href="https://app.bogged.finance/bsc/swap?&embed=1&tokenIn=BNB&tokenOut=0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95&theme=dark"
              className="navItem link"
            >
              SWAP
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
