import React from "react";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../utils/Connectors";
import metamask from "../assets/icons/metamask.svg";
import walletconnect from "../assets/icons/walletconnect.png";
import styledComponents from "styled-components";
export default function Model({showModel, handleClick, setShowModel }) {
  const Container = styledComponents.div`
    position: fixed;
    background: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    `;
  const ContentBox = styledComponents.div`
    background: #1d00c6;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 400px;
    justify-content: space-around;
    border-radius: 10px;
    @media screen and (max-width: 900px) {
      width: 90%
    }
    `;
  const Button = styledComponents.div`
    background: none;
    text-transform: uppercase;
    flex-direction: column;
    row-gap: 10px;
    border: none;
    cursor: pointer;
    widht: 100%;
    height: 100%;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center; 
    &:hover {
        background: rgba(0,0,0,0.2);
    }
    `;
  const Image = styledComponents.img`
    width: 100px;
    height: 100px
    @media screen and (max-width: 760px) {
      width: 60px;
      height: 60px
    }
    `;
  const HR = styledComponents.hr`
    width: 100%;
    background: rgba(0,0,0,0.2);
    padding: 0;
    margin: 0
    `;
  const { activate } = useWeb3React();
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };
  return (
    <div
      style={{
        display: `${showModel ? "block" : "none"}`,
      }}
      onClick={() => setShowModel(false)}
    >
      <Container>
        <ContentBox>
          <Button onClick={handleClick}>
            <Image src={metamask} alt="" />
            Metamask
          </Button>
          <HR></HR>
          <Button
            onClick={() => {
              activate(connectors.walletConnect);
              setProvider("walletConnect");
            }}
          >
            <Image src={walletconnect} alt="" />
            walletconnect
          </Button>
        </ContentBox>
      </Container>
    </div>
  );
}
