import Web3 from 'web3';
import { REWARD_CONTRACT, MAIN_CONTRACT, PROXY_CONTRACT } from './utils/ContractDetails';
var web3;
if (Web3.givenProvider) {
    // web3 = new Web3(new Web3.providers.HttpProvider(infuraURL))
    web3 = new Web3(Web3.givenProvider);
    console.log(web3)
} else {
    web3 = new Web3('https://bsc-dataseed1.binance.org:443');
    const account = web3.eth.accounts.create();
}

const Contract = new web3.eth.Contract(REWARD_CONTRACT.abi, REWARD_CONTRACT.address);

export const Contract_Proxy = new web3.eth.Contract(MAIN_CONTRACT.abi, PROXY_CONTRACT.address);
export default Contract;