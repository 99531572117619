import React from 'react'
import SectionOne from './SectionOne'
import SectionThree from './SectionThree'
import SectionTwo from './SectionTwo'

export default function index({ currentAccount }) {
  const style = {
    container: {
      padding: '350px  0 130px 0'
    }
  }
  return (
    <div className='container-div'>

      <div className='reward-container'>
        <SectionOne currentAccount={currentAccount} />
        <SectionTwo />
        <SectionThree />
      </div>
    </div>
  )
}
