import React from 'react'
import './style.css'
export default function Graph() {
  const graph = `
  
  `
  return (
    <div>
      <div id="dexscreener-embed"><iframe src="https://dexscreener.com/bsc/0x1F5Cb99149cD545e62a764faca59F9A4E13ffdb3?embed=1&theme=dark&trades=0"></iframe></div>
    </div>
  )
}