import React from 'react'
import { Chart } from '../../components/Chart'
import Chart1 from '../../assets/charts/chart 1.png'
import ChartBoth from '../../assets/charts/chart both.png'
import Chart2 from '../../assets/charts/chart 2.png'
export default function Fees() {
    const style = {
        container: {
            justifyContent: 'space-around',
        },
        labelWrapper: {
            marginTop: '30px'
        }
    }
    return (
        <div>
            <div style={style.container} className='flex fee-charts-wrapper'>
                {/* <img src={ChartBoth} alt="" /> */}
                 <img src={Chart1} alt="" />
                <img src={Chart2} alt="" />
            </div>
            <div style={style.labelWrapper} className='flex justify-center gap-x-4 text-white chart-labels'>
                <div className='flex'>
                    <div style={{
                        width: '21px',
                        height: '21px',
                        background: '#BAC712',
                        borderRadius: '50px',
                        marginRight: '5px'
                    }}></div>
                    <div>Rewards</div>
                </div>
                <div className='flex'>
                    <div style={{
                        width: '21px',
                        height: '21px',
                        background: '#961FD4',
                        borderRadius: '50px',
                        marginRight: '5px'
                    }}></div>
                    Marketing
                </div>
                <div className='flex'>
                    <div style={{
                        width: '21px',
                        height: '21px',
                        background: '#8341FF',
                        borderRadius: '50px',
                        marginRight: '5px'
                    }}></div>
                    LP</div>
                <div className='flex'>
                    <div style={{
                        width: '21px',
                        height: '21px',
                        background: '#0B57D4',
                        borderRadius: '50px',
                        marginRight: '5px'
                    }}></div>
                    Buyback
                </div>
            </div>
        </div>
    )
}
